import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import { SfCrossMIcon } from '@alfalab/icons-glyph';

import { RootState } from 'src/core/rootReducer';
import { Button } from 'src/components/Button';
import { config } from 'src/config/config';
import { ApiStatus } from 'src/core/ApiStatus';
import { storage } from 'src/core/storage';

export const ConfirmEmail = () => {
  const authData = useSelector((state: RootState) => state.auth.data);

  const [sending, setSending] = useState(false);
  const [sendingStatus, setSendingStatus] = useState<ApiStatus>(ApiStatus.INITIAL);
  const [hidden, setHidden] = useState(false);

  const onSendClick = useCallback(() => {
    setSending(true);

    axios
      .post(`${config.api}/confirm-email`, {}, { withCredentials: true })
      .then(() => {
        setSending(false);
        setSendingStatus(ApiStatus.SUCCESS);

        setTimeout(() => {
          setHidden(true);
        }, 1500);
      })
      .catch(() => {
        setSending(false);
        setSendingStatus(ApiStatus.ERROR);
      });
  }, []);

  const onClose = useCallback(() => {
    setHidden(true);
    storage.setItem('confirmEmailHide', Date.now().toString());
  }, []);

  return (
    <Wrap hide={hidden}>
      <Inner>
        {sendingStatus === ApiStatus.SUCCESS ? (
          <>Письмо успешно отправлено.</>
        ) : sendingStatus === ApiStatus.ERROR ? (
          <>
            <TextWrap>
              <span>Произошла ошибка :(</span>
              <SfCrossMIcon onClick={onClose} className="confirm-close-icon" />
            </TextWrap>
            <Button view="primary" size="xs" onClick={onSendClick} loading={sending}>
              Попробовать еще раз
            </Button>
          </>
        ) : (
          <>
            <TextWrap>
              <span>
                На почту <Email>{authData.email}</Email> выслано письмо со ссылкой, перейдите по ней
                для подтверждения почты.
                <br />
                Проверьте папку «‎спам»‎, если письмо не доходит.
              </span>
              <SfCrossMIcon onClick={onClose} className="confirm-close-icon" />
            </TextWrap>
            <Button view="primary" size="xs" onClick={onSendClick} loading={sending}>
              Выслать письмо еще раз
            </Button>
          </>
        )}
      </Inner>
    </Wrap>
  );
};

const Wrap = styled.div<{ hide: boolean }>`
  width: 100%;
  background-color: #f1f1ff;
  max-height: 1000px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;

  ${(p) => p.hide && `max-height: 0;`};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 15px;
  font-size: 14px;
  color: #4a4a4a;

  @media screen and (min-width: 960px) {
    padding: 15px 24px;
  }

  @media screen and (min-width: 1320px) {
    margin: 0;
    margin-left: 240px;
  }
`;

const Email = styled.span`
  color: #000;
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  > span {
    margin-right: 20px;
  }

  .confirm-close-icon {
    flex-shrink: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    :hover {
      opacity: 1;
    }
  }
`;
