import React, { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormLayout, FormLayoutGroup, ScreenSpinner, Separator } from '@vkontakte/vkui';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';

import { RootState } from 'src/core/rootReducer';
import { BaseGame, BoxSettings } from 'src/games/common/types';
import { ColorSelect, GameStyleSelect, ImageSelect } from 'src/vk-app/components';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { AllGamesInitState } from 'src/store/game/reducers/allGamesReducer';
import { GameId } from 'src/store/game/types';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { config } from 'src/config/config';
import { nyBackgrounds } from '../common/design';

type Props = {
  initialValues: BoxSettings;
};

const boxVariants = [
  'https://cdn2.embedgames.app/BOX/gift-1.png',
  'https://cdn2.embedgames.app/BOX/gift-2.png',
  'https://cdn2.embedgames.app/BOX/gift-3.png',
  'https://cdn2.embedgames.app/BOX/gift-4.png',
  'https://cdn2.embedgames.app/BOX/gift-5.png',
  'https://cdn2.embedgames.app/BOX/gift-6.png',
  'https://cdn2.embedgames.app/BOX/gift-7.png',
  'https://cdn2.embedgames.app/BOX/gift-8.png',
  //
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_1.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_2.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_3.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_4.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_5.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_6.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_7.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_8.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_9.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_10.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_11.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_12.png',
  //
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_1.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_2.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_3.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_4.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_5.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_7.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_8.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_9.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_10.png',
  //
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_1.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_2.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_3.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_4.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_5.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_6.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_7.png',
];

const Design: FC<Props> = () => {
  const formSelector = formValueSelector('game');
  const values = useSelector<RootState>((state) => state.form.game?.values) as BoxSettings;
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const mainGameSettingsId = useSelector<RootState, number>((state) =>
    formSelector(state, 'mainGameSettingsId')
  );

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const games = useSelector<RootState, AllGamesInitState>((state) => state.games.allGames);

  const gameInfo = games.data.find((game) => game.gameId === GameId.BOX);

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  const view = useSelector<RootState, BaseGame['view']>((state) => formSelector(state, 'view'));
  const secondSectionBgColor = useSelector<RootState, string>((state) =>
    formSelector(state, 'secondSectionBgColor')
  );
  const backgroundColor = useSelector<RootState, string>((state) =>
    formSelector(state, 'backgroundColor')
  );

  const textPreviewBgColor = view === 'classic' ? secondSectionBgColor : backgroundColor;

  return (
    <Wrap>
      <FormLayout>
        <FormLayoutGroup top="Цвет заголовка">
          <Field
            name="titleColor"
            component={ColorSelect}
            TextPreview={
              <TitlePreview color={values.titleColor} backgroundColor={textPreviewBgColor}>
                {values.titleText}
              </TitlePreview>
            }
          />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Цвет подзаголовка">
          <Field
            name="subtitleColor"
            component={ColorSelect}
            TextPreview={
              <SubtitlePreview color={values.subtitleColor} backgroundColor={textPreviewBgColor}>
                {values.subtitleText}
              </SubtitlePreview>
            }
          />
        </FormLayoutGroup>

        <Separator />

        <BoxesWrap>
          <FormLayoutGroup className="box">
            <LabelsWrap>
              <Label className="FormLayout__row-top">Коробка #1</Label>
              <LabelDescription className="FormLayout__row-top">
                Отображается только на десктопе
              </LabelDescription>
            </LabelsWrap>

            <Field
              name="boxes[0]"
              component={ImageSelect}
              variants={boxVariants}
              title="Выберите коробку"
              uploadButtonText="Загрузить свою"
              canUpload={true}
              uploadUrl={`${config.api}/vk/upload-box-image/${mainGameSettingsId}?i=0`}
              setPreviewSrc={(src: string) => {
                dispatch(change('game', 'boxes[0]', src));
              }}
            />
          </FormLayoutGroup>

          <FormLayoutGroup className="box">
            <LabelsWrap>
              <Label className="FormLayout__row-top">Коробка #2</Label>
              <LabelDescription className="FormLayout__row-top">
                Отображается только на десктопе
              </LabelDescription>
            </LabelsWrap>

            <Field
              name="boxes[1]"
              component={ImageSelect}
              variants={boxVariants}
              title="Выберите коробку"
              uploadButtonText="Загрузить свою"
              canUpload={true}
              uploadUrl={`${config.api}/vk/upload-box-image/${mainGameSettingsId}?i=1`}
              setPreviewSrc={(src: string) => {
                dispatch(change('game', 'boxes[1]', src));
              }}
            />
          </FormLayoutGroup>

          <FormLayoutGroup className="box">
            <Label className="FormLayout__row-top">Коробка #3</Label>
            <Field
              name="boxes[2]"
              component={ImageSelect}
              variants={boxVariants}
              title="Выберите коробку"
              uploadButtonText="Загрузить свою"
              canUpload={true}
              uploadUrl={`${config.api}/vk/upload-box-image/${mainGameSettingsId}?i=2`}
              setPreviewSrc={(src: string) => {
                dispatch(change('game', 'boxes[2]', src));
              }}
            />
          </FormLayoutGroup>

          <FormLayoutGroup className="box">
            <Label className="FormLayout__row-top">Коробка #4</Label>
            <Field
              name="boxes[3]"
              component={ImageSelect}
              variants={boxVariants}
              title="Выберите коробку"
              uploadButtonText="Загрузить свою"
              canUpload={true}
              uploadUrl={`${config.api}/vk/upload-box-image/${mainGameSettingsId}?i=3`}
              setPreviewSrc={(src: string) => {
                dispatch(change('game', 'boxes[3]', src));
              }}
            />
          </FormLayoutGroup>

          <FormLayoutGroup className="box">
            <Label className="FormLayout__row-top">Коробка #5</Label>
            <Field
              name="boxes[4]"
              component={ImageSelect}
              variants={boxVariants}
              title="Выберите коробку"
              uploadButtonText="Загрузить свою"
              canUpload={true}
              uploadUrl={`${config.api}/vk/upload-box-image/${mainGameSettingsId}?i=4`}
              setPreviewSrc={(src: string) => {
                dispatch(change('game', 'boxes[4]', src));
              }}
            />
          </FormLayoutGroup>
        </BoxesWrap>

        <Separator />

        <FormLayoutGroup top="Внешний вид">
          <GameStyleSelect />
        </FormLayoutGroup>

        <FormLayoutGroup top="Цвет фона">
          <Field
            name={view === 'classic' ? 'secondSectionBgColor' : 'backgroundColor'}
            component={ColorSelect}
            top="Цвет фона"
          />
        </FormLayoutGroup>

        <FormLayoutGroup top="Фон">
          <Field
            name="backgroundImgSrc"
            component={ImageSelect}
            variants={[...(gameInfo?.backgroundVariants || []), ...nyBackgrounds]}
            title="Выберите фон"
            className="select-background"
            size={[150, 150]}
            uploadUrl={`${config.api}/vk/upload-game-background/${mainGameSettingsId}`}
            setPreviewSrc={(src: string) => {
              dispatch(change('game', 'backgroundImgSrc', src));
            }}
            backgroundSize="cover"
            canUpload={true}
            disabled={view === 'mono'}
          />
        </FormLayoutGroup>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div`
  .select-background {
    margin: 0 12px;
  }
`;

const TitlePreview = styled.div<{ color?: string; backgroundColor?: string }>`
  margin: 0 12px;
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
`;

const SubtitlePreview = styled.div<{ color?: string; backgroundColor?: string }>`
  margin: 0 12px;
  font-size: 16px;
  line-height: 20px;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
`;

const BoxesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  .box {
    margin: 12px;
  }
`;

const Label = styled.span``;

const LabelsWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150px;
`;

const LabelDescription = styled.div`
  font-size: 10px;
  line-height: 12px;
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Design);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Design };
