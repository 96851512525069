import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from 'src/core/rootReducer';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
    marginBottom: 10,
  },
  text: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    marginBottom: '10px',
    '@media (min-width: 1320px)': {
      color: '#282a4a'
    }
  },
}));

const Profile = (props: any) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const user = useSelector((state: RootState) => state.auth.data);

  return (
    <Link to="/account">
      <div {...rest} className={clsx(classes.root, className)}>
        <Avatar alt="Person" className={classes.avatar} src={user.photo} />
        <Typography variant="h4" className={classes.text}>
          {user.name}
        </Typography>
        <Typography variant="body2" className={classes.text}>
          {user.email}
        </Typography>
      </div>
    </Link>
  );
};

export default Profile;
