import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, colors } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: 0,
  },
  item: {
    display: 'flex',
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      width: '30px',
      height: '30px',
      marginRight: 10,
    },
    fontSize: 16,
    padding: theme.spacing(2),
    paddingTop: 10,
    paddingBottom: 10,
    width: 'calc(100% - 10px)',
    margin: '0 auto 10px',
    borderRadius: 10,
    '&.active': {
      backgroundColor: '#5f31e0',
    },
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const SidebarNav = (props: any) => {
  const { pages, className, onClose, ...rest } = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page: any) => (
        <ListItem
          className={clsx(classes.item, page.href === window.location.pathname ? 'active' : '')}
          disableGutters
          key={page.title}
          onClick={onClose}
        >
          <SLink to={page.href} active={page.href === window.location.pathname}>
            {page.icon} {page.title}
          </SLink>
        </ListItem>
      ))}
    </List>
  );
};

const SLink = styled(Link)<{ active: boolean }>`
  color: #fff;

  @media (min-width: 1320px) {
    color: #282a4a;

    ${(p) =>
      p.active &&
      `
        color: #fff
      `};
  }
`;

export default SidebarNav;
