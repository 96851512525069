export const nyBackgrounds = [
  'https://cdn2.embedgames.app/ny-bg/1.webp',
  'https://cdn2.embedgames.app/ny-bg/2.webp',
  'https://cdn2.embedgames.app/ny-bg/3.webp',
  'https://cdn2.embedgames.app/ny-bg/4.webp',
  'https://cdn2.embedgames.app/ny-bg/5.webp',
  'https://cdn2.embedgames.app/ny-bg/6.webp',
  'https://cdn2.embedgames.app/ny-bg/7.webp',
  'https://cdn2.embedgames.app/ny-bg/8.webp',
  'https://cdn2.embedgames.app/ny-bg/9.webp',
]
