import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import {
  MainPage,
  SignInPage,
  NotFoundPage,
  SignUpPage,
  GamePage,
  MyGamesListPage,
  MyGamePage,
  ResetPassPage,
  NewPassPage,
  TargetsPage,
  GameStatisticsPage,
  TermOfUsePage,
  EmailConfirmedPage,
  AccountPage,
  PricingPage,
  SpacePage,
  FromCheckinPage,
  FAQPage,
  EmbedGamesPolicyPage,
  GamesPage,
  OAuthPage,
} from './pages';
import { connect, ReactReduxContext } from 'react-redux';
import PolicyPage from './pages/Policy/Policy';
import BaseLayout from './components/BaseLayout/BaseLayout';
import LandingLayout from './components/LandingLayout/LandingLayout';
import { RootState } from './core/rootReducer';
import { authActions, AuthInitialState } from './store';
import { ApiStatus } from './core/ApiStatus';
import { ChunkLoader } from './components';
import { allGamesActions } from './store/game/actions';
import { myGamesActions } from './store/my-games/actions';
import { targetsActions } from './store/targets/actions';
import { history } from './core/store';
import { storage } from './core/storage';

type RefInfo = {
  refId: string;
  date: number;
};

const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;

class App extends React.Component<any> {
  componentDidMount() {
    const {
      auth,
      getAuthData,
      allGames,
      getAllGames,
      myGames,
      getMyGames,
      targets,
      getTargets,
      router,
    } = this.props;

    if (router.location.pathname.includes('/s/') || router.location.pathname.includes('/vk')) {
      return;
    }

    if (auth.status === ApiStatus.INITIAL) {
      getAuthData();
    }

    if (allGames.status !== ApiStatus.SUCCESS) {
      getAllGames({});
    }

    if (myGames.status !== ApiStatus.SUCCESS) {
      getMyGames();
    }

    if (targets.status !== ApiStatus.SUCCESS) {
      getTargets();
    }

    // Реферальная программа
    if (router.location?.query?.refId) {
      const refId = router.location?.query?.refId;

      try {
        const refInfoStr = storage.getItem('refInfo');
        const now = Date.now();

        if (refInfoStr) {
          const { date } = JSON.parse(refInfoStr) as RefInfo;

          if (now - date > ONE_MONTH) {
            storage.setItem('refInfo', JSON.stringify({ refId, date: now }));
          }
        } else {
          storage.setItem('refInfo', JSON.stringify({ refId, date: now }));
        }
      } catch (err) {
        //
      }
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  }

  render() {
    const { auth } = this.props;

    const authorized = auth.data && !!auth.data.id;

    return (
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <Switch>
          <Route exact path="/" render={() => <MainPage />} />

          <Route
            exact
            path="/games"
            render={(props) => {
              if (auth.status === ApiStatus.INITIAL || auth.status === ApiStatus.FETCHING) {
                return <ChunkLoader />;
              }

              return auth.data.id ? (
                <BaseLayout auth={auth}>
                  <GamesPage {...props} auth={auth} />
                </BaseLayout>
              ) : (
                <LandingLayout auth={auth} authorized={authorized}>
                  <GamesPage {...props} auth={auth} />
                </LandingLayout>
              );
            }}
          />

          <Route
            path="/game/:gameId"
            render={(props) => {
              if (auth.status === ApiStatus.INITIAL || auth.status === ApiStatus.FETCHING) {
                return <ChunkLoader />;
              }

              return auth.data.id ? (
                <BaseLayout auth={auth}>
                  <GamePage {...props} auth={auth} />
                </BaseLayout>
              ) : (
                <LandingLayout auth={auth} authorized={authorized}>
                  <GamePage {...props} auth={auth} />
                </LandingLayout>
              );
            }}
          />
          <Route
            path="/my-games/:gameId/:mainSettingId/statistics"
            render={renderAuthZone({ Component: GameStatisticsPage, auth, authorized })}
          />
          <Route
            path="/my-games/:gameId/:mainSettingId"
            render={renderAuthZone({ Component: MyGamePage, auth, authorized })}
          />
          <Route
            path="/my-games/:gameId"
            render={renderAuthZone({ Component: MyGamePage, auth, authorized })}
          />
          <Route
            path="/my-games"
            render={renderAuthZone({ Component: MyGamesListPage, auth, authorized })}
          />
          <Route
            path="/targets"
            render={renderAuthZone({ Component: TargetsPage, auth, authorized })}
          />
          <Route
            path="/account"
            component={renderAuthZone({ Component: AccountPage, auth, authorized })}
          />

          <Route
            path="/sign-in"
            render={(props) =>
              authorized ? <Redirect to="/my-games" /> : <SignInPage {...props} auth={auth} />
            }
          />

          <Route
            path="/reset-pass"
            render={(props) =>
              authorized ? <Redirect to="/my-games" /> : <ResetPassPage {...props} auth={auth} />
            }
          />

          <Route
            path="/sign-up"
            render={(props) =>
              authorized ? <Redirect to="/my-games" /> : <SignUpPage {...props} auth={auth} />
            }
          />

          <Route
            path="/new-pass"
            render={(props) =>
              authorized ? <Redirect to="/my-games" /> : <NewPassPage {...props} auth={auth} />
            }
          />

          <Route
            path="/policy"
            component={renderLandingZone({ Component: PolicyPage, auth, authorized })}
          />

          <Route
            path="/embed-games-policy"
            component={renderLandingZone({ Component: EmbedGamesPolicyPage, auth, authorized })}
          />

          <Route
            path="/term-of-use"
            component={renderLandingZone({ Component: TermOfUsePage, auth, authorized })}
          />

          <Route
            path="/faq"
            component={renderLandingZone({ Component: FAQPage, auth, authorized })}
          />

          <Route
            path="/email-confirmed"
            component={renderLandingZone({ Component: EmailConfirmedPage, auth, authorized })}
          />

          <Route
            exact
            path="/pricing"
            render={(props) => {
              if (auth.status === ApiStatus.INITIAL || auth.status === ApiStatus.FETCHING) {
                return <ChunkLoader />;
              }

              return auth.data.id ? (
                <BaseLayout auth={auth}>
                  <PricingPage {...props} auth={auth} />
                </BaseLayout>
              ) : (
                <LandingLayout auth={auth} authorized={authorized}>
                  <PricingPage {...props} auth={auth} />
                </LandingLayout>
              );
            }}
          />

          <Route
            path="/s/:targetUrl"
            component={(props: any) => {
              return <SpacePage {...props} />;
            }}
          />

          <Route
            path="/from-checkin"
            component={renderAuthZone({ Component: FromCheckinPage, auth, authorized })}
          />

          <Route path="/oauth/:oauthType" render={(props) => <OAuthPage {...props} />} />

          <Route
            render={(props) => <NotFoundPage {...props} authorized={authorized} auth={auth} />}
          />
        </Switch>
      </ConnectedRouter>
    );
  }
}

type AuthZoneRenderProps = {
  auth: AuthInitialState;
  Component: any;
  authorized: boolean;
};

type LandingZoneRenderProps = {
  auth: AuthInitialState;
  Component: any;
  authorized: boolean;
};

const renderAuthZone =
  ({ Component, auth }: AuthZoneRenderProps) =>
  (props: any) => {
    if (auth.status === ApiStatus.INITIAL || auth.status === ApiStatus.FETCHING) {
      return <ChunkLoader />;
    }

    if (auth.data.id) {
      return (
        <BaseLayout auth={auth}>
          <Component {...props} auth={auth} />
        </BaseLayout>
      );
    }

    return <Redirect to="/sign-in" />;
  };

const renderLandingZone =
  ({ Component, auth, authorized }: LandingZoneRenderProps) =>
  (props: any) =>
    (
      <LandingLayout auth={auth} authorized={authorized}>
        <Component {...props} auth={auth} />
      </LandingLayout>
    );

const withStore = connect(
  (state: RootState) => ({
    auth: state.auth,
    allGames: state.games.allGames,
    myGames: state.myGames,
    targets: state.targets,
    router: state.router,
  }),
  (dispatch) => ({
    getAuthData: () => dispatch(authActions.getAuthData.started({})),
    getAllGames: () => dispatch(allGamesActions.get.started({})),
    getMyGames: () => dispatch(myGamesActions.get.started({})),
    getTargets: () => dispatch(targetsActions.get.started({})),
  })
)(App);

export default withStore;
