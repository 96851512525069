import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { Link } from 'react-router-dom';
import { AuthInitialState } from 'src/store';
import { ApiStatus } from 'src/core/ApiStatus';

import logo from '../../../../assets/icons/logo_white.png';

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: 'none',
    position: 'static',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

type TopBarProps = {
  auth: AuthInitialState;
  link?: any;
  className: string;
  hideBurger?: boolean;
  onSidebarOpen: () => void;
};

const Topbar = (props: TopBarProps) => {
  const { className, onSidebarOpen, auth, link, hideBurger, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Inner hideBurger={hideBurger}>
          <a href="https://embedgames.app/" className="logo-link">
            <Logo />
          </a>

          <div className={classes.flexGrow} />

          {auth.status === ApiStatus.INITIAL || auth.status === ApiStatus.FETCHING ? null : auth
              .data.id ? (
            <HeaderSection>
              {link ? link : null}

              {!hideBurger && (
                <IconButton color="inherit" onClick={onSidebarOpen}>
                  <MenuIcon className="menu-icon" />
                </IconButton>
              )}
            </HeaderSection>
          ) : (
            <HeaderSection>
              <Link to="/pricing" className="header-link">
                Тарифы
              </Link>
              <Link to="/sign-in">
                <Button size="s">Войти</Button>
              </Link>
            </HeaderSection>
          )}
        </Inner>
      </Toolbar>
    </AppBar>
  );
};

const Inner = styled.div<{ hideBurger?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1102px;
  margin: 0 auto;

  .menu-icon {
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: 1320px) {
    ${(p) =>
      p.hideBurger &&
      `
        max-width: initial;
        margin-left: 240px;
    `};
  }
`;

const Logo = styled.div`
  width: 200px;
  height: 40px;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (min-width: 960px) {
    width: 250px;
    height: 50px;
  }
`;

const HeaderSection = styled.div`
  .my-games-header-link {
    display: none;
  }

  .header-link {
    margin-right: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
  }

  @media screen and (min-width: 960px) {
    display: flex;
    align-items: center;

    .my-games-header-link {
      display: flex;
      margin-right: 30px;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
    }
  }
`;

export default Topbar;
