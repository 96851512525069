import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Provider, ReactReduxContext } from 'react-redux';
import store from './core/store';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import theme from './core/muiTheme';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://01cd28d6da9fdf01f24e8362c28a43a9@o4505788935897088.ingest.sentry.io/4506173533192192',
  });
}

ReactDOM.render(
  <div className="App">
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <SCThemeProvider theme={theme}>
        <Provider store={store} context={ReactReduxContext}>
          <App />
        </Provider>
      </SCThemeProvider>
    </ThemeProvider>
  </div>,
  document.getElementById('root')
);
