import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import InputIcon from '@material-ui/icons/Input';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import GamepadIcon from '@material-ui/icons/Gamepad';
import { useDispatch } from 'react-redux';
import WebIcon from '@material-ui/icons/Web';
import PersonIcon from '@material-ui/icons/Person';

import { Profile, SidebarNav } from './components';
import { authActions } from 'src/store/auth/reducer';

const useStyles = makeStyles((theme: any) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& a': {
      textDecoration: 'none',
    },
    paddingTop: '48px',
    color: theme.palette.primary.contrastText,
    '@media (min-width: 1320px)': {
      backgroundColor: '#fff',
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  signOutButton: {
    lineHeight: '18px',
    justifyContent: 'center',
    margin: '0 15px',
    borderRadius: 10,
    color: 'inherit',
    '@media (min-width: 1320px)': {
      color: '#282a4a',
    },
  },
}));

const Sidebar = (props: any) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Каталог игр',
      href: '/games',
      icon: <GamepadIcon />,
    },
    {
      title: 'Мои игры',
      href: '/my-games',
      icon: <SportsEsportsIcon />,
    },
    {
      title: 'Мои площадки',
      href: '/targets',
      icon: <WebIcon />,
    },
    {
      title: 'Аккаунт',
      href: '/account',
      icon: <PersonIcon />,
    },
  ];

  const dispatch = useDispatch();

  const onLogoutClick = () => {
    dispatch(authActions.logout.started({}));
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <a href="https://embedgames.app/">
          <Logo>
            <span>EmbedGames</span>
          </Logo>
        </a>
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} onClose={onClose} />
        <Divider className={classes.divider} />
        <Profile />

        <IconButton className={classes.signOutButton} onClick={onLogoutClick}>
          <ButtonLabel>Выйти</ButtonLabel> <InputIcon />
        </IconButton>
      </div>
    </Drawer>
  );
};

const ButtonLabel = styled.div`
  margin-right: 10px;
  font-size: 18px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  padding-bottom: 0;
  text-align: left;

  > span {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
  }

  @media (min-width: 1320px) {
    > span {
      color: #000;
    }
  }
`;

export default Sidebar;
