import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Wrap = styled.div`
  width: 100%;
  max-width: 1150px;
  box-sizing: border-box;
  padding: 15px;
  margin: 0px auto 50px;
  min-height: 70vh;

  @media screen and (min-width: 960px) {
    padding: 15px 24px;
  }
`;

const PolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrap>
      <Helmet>
        <title>EmbedGames — политика конфиденциальности</title>
      </Helmet>
      <h1>Политика конфиденциальности</h1>
      <br />
      <b>
        В целях сохранения конфиденциальности мы не предоставляем Ваши личные данные третьим лицам.
      </b>
      <br />
      <br />
      <b>Меры, применяемые для защиты персональной информации пользователей.</b>
      <br />
      embedgames.app анонимно использует файлы cookies для запоминания персональной информации и
      обеспечения более удобного взаимодействия с Компанией. Вы можете отключить использование
      cookies в параметрах настройки браузера, однако в этом случае некоторые функции сайта могут
      работать неправильно. В целях сохранения конфиденциальности Вашей личной информации мы
      принимаем необходимые и достаточные организационные и технические меры безопасности, сводящие
      к минимуму несанкционированный доступ к Вашей персональной информации или ее раскрытию. В
      частности, мы используем SSL - шифрование для защиты персональных сведений.
      <br />
      <br />
      <b>Добровольное предоставление данных.</b>
      <br />В отношении персональной информации пользователя сохраняется ее конфиденциальность,
      кроме случаев добровольного предоставления пользователем информации о себе для общего доступа
      неограниченному кругу лиц.
      <br />
      <br />
      <b>Условия обработки персональной информации пользователей.</b>
      <br />
      Использование сайта embedgames.app означает согласие пользователей с настоящей Политикой
      конфиденциальности и условиями обработки его персональных данных.
      <br />
      <br />
      <b>Обратная связь.</b>
      <br />
      Если у вас возникли вопросы в отношении настоящей Политики конфиденциальности или обработки
      персональных данных, пожалуйста, свяжитесь с нами: hello@embedgames.app
    </Wrap>
  );
};

export default PolicyPage;
