import React, { Suspense } from 'react';

import { ChunkLoader } from 'src/components';

const SignIn = React.lazy(() => import(/* webpackChunkName: "main-page" */ './SignIn/SignIn'));
const SignUp = React.lazy(() => import(/* webpackChunkName: "main-page" */ './SignUp/SignUp'));
const OAuth = React.lazy(() => import(/* webpackChunkName: "oauth-page" */ './OAuth/OAuth'));
const ResetPass = React.lazy(
  () => import(/* webpackChunkName: "main-page" */ './ResetPass/ResetPass')
);
const NewPass = React.lazy(() => import(/* webpackChunkName: "main-page" */ './ResetPass/NewPass'));
const Main = React.lazy(() => import(/* webpackChunkName: "main-page" */ './Main/Main'));
const NotFound = React.lazy(
  () => import(/* webpackChunkName: "not-found-page" */ './NotFound/NotFound')
);
const Policy = React.lazy(() => import(/* webpackChunkName: "policy-page" */ './Policy/Policy'));
const EmbedGamesPolicy = React.lazy(
  () =>
    import(/* webpackChunkName: "embed-games-policy-page" */ './EmbedGamesPolicy/EmbedGamesPolicy')
);
const TermOfUse = React.lazy(
  () => import(/* webpackChunkName: "term-of-use-page" */ './TermOfUse/TermOfUse')
);
const Game = React.lazy(() => import(/* webpackChunkName: "game-page" */ './Game/Game'));
const MyGamesList = React.lazy(
  () => import(/* webpackChunkName: "my-games-list-page" */ './MyGames/List')
);
const MyGame = React.lazy(() => import(/* webpackChunkName: "my-game-page" */ './MyGames/Game'));
const Targets = React.lazy(
  () => import(/* webpackChunkName: "targets-page" */ './Targets/Targets')
);
const GameStatistics = React.lazy(
  () => import(/* webpackChunkName: "game-statistics-page" */ './GameStatistics/GameStatistics')
);
const EmailConfirmed = React.lazy(
  () => import(/* webpackChunkName: "email-confirmed-page" */ './EmailConfirmed/EmailConfirmed')
);
const Account = React.lazy(
  () => import(/* webpackChunkName: "account-page" */ './Account/Account')
);
const Pricing = React.lazy(
  () => import(/* webpackChunkName: "pricing-page" */ './Pricing/Pricing')
);
const Space = React.lazy(/* webpackChunkName: "space-page" */ () => import('./Space/Space'));
const FromCheckin = React.lazy(
  /* webpackChunkName: "from-checkin-page" */ () => import('./FromCheckin/FromCheckin')
);
const FAQ = React.lazy(/* webpackChunkName: "faq-page" */ () => import('./FAQ/FAQ'));
const Games = React.lazy(/* webpackChunkName: "games-page" */ () => import('./Games/index'));

const withSuspense = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<ChunkLoader />}>
      <Component {...props} />
    </Suspense>
  );

const PolicyPage = withSuspense(Policy);
const TermOfUsePage = withSuspense(TermOfUse);
const MainPage = withSuspense(Main);
const NotFoundPage = withSuspense(NotFound);
const GamePage = withSuspense(Game);
const MyGamesListPage = withSuspense(MyGamesList);
const TargetsPage = withSuspense(Targets);
const GameStatisticsPage = withSuspense(GameStatistics);
const EmailConfirmedPage = withSuspense(EmailConfirmed);
const AccountPage = withSuspense(Account);
const PricingPage = withSuspense(Pricing);
const MyGamePage = withSuspense(MyGame);
const SpacePage = withSuspense(Space);
const SignInPage = withSuspense(SignIn);
const SignUpPage = withSuspense(SignUp);
const ResetPassPage = withSuspense(ResetPass);
const NewPassPage = withSuspense(NewPass);
const FromCheckinPage = withSuspense(FromCheckin);
const FAQPage = withSuspense(FAQ);
const EmbedGamesPolicyPage = withSuspense(EmbedGamesPolicy);
const GamesPage = withSuspense(Games);
const OAuthPage = withSuspense(OAuth);

export {
  SignInPage,
  MainPage,
  NotFoundPage,
  SignUpPage,
  PolicyPage,
  TermOfUsePage,
  GamePage,
  MyGamesListPage,
  MyGamePage,
  ResetPassPage,
  NewPassPage,
  TargetsPage,
  GameStatisticsPage,
  EmailConfirmedPage,
  AccountPage,
  PricingPage,
  SpacePage,
  FromCheckinPage,
  FAQPage,
  EmbedGamesPolicyPage,
  GamesPage,
  OAuthPage,
};
