import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

import { Button } from 'src/components/Button';
import { Sidebar, Topbar, ConfirmEmail } from './components';
import { Footer } from '../Footer';
import { AuthInitialState } from 'src/store';
import { useWindowResize } from 'src/core/hooks/useWindowResize';
import { storage } from 'src/core/storage';
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingTop: 0,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  content: {
    height: '100%',
  },
}));

type BaseLayoutProps = {
  auth: AuthInitialState;
  children: ReactNode;
};

const cookies = new Cookies();

const twelveHours = 12 * 60 * 60 * 1000;

const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
const cookieName = 'eg-new-service-modal-viewed';

const features = [
  '✔ Нет ограничений на количество сайтов',
  '✔ Возможность добавлять несколько виджетов одновременно на сайт',
  '✔ Конструктор форм: можно добавить любые поля: имя, город и т.п',
  '✔ Удобная работа с купонами',
  '✔ Больше возможностей для кастомизации виджетов',
];

const BaseLayout = (props: BaseLayoutProps) => {
  const { children, auth } = props;

  const classes = useStyles();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [clientWidth, setClientWidth] = useState(document.body.clientWidth);
  const [open, setOpen] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const viewed = cookies.get(cookieName);

    if (viewed) {
      return;
    }

    setOpen(true);
    cookies.set('eg-new-service-modal-viewed', '1', { expires: new Date(Date.now() + ONE_MONTH) });
  }, []);

  let showEmailConfirm = !auth.data.emailConfirmed;

  const lastShowed = storage.getItem('confirmEmailHide');

  if (lastShowed) {
    const diff = Date.now() - +lastShowed;

    if (diff < twelveHours) {
      showEmailConfirm = false;
    }
  }

  useWindowResize(() => {
    setClientWidth(document.body.clientWidth);
  });

  const desktopL = clientWidth > 1320;

  return (
    <Wrap
      className={clsx({
        [classes.root]: true,
      })}
    >
      <SwitchVersionWrapper>
        <Content>
          <TextDescription>
            Мы выпустили новую версию сервиса, улучшенную и переработанную
          </TextDescription>

          <Button color="inherit" href="https://app.embedgames.app/" size="xs">
            Переключиться на новую версию
          </Button>
        </Content>
      </SwitchVersionWrapper>

      <Topbar
        onSidebarOpen={handleSidebarOpen}
        className="top-bar"
        auth={auth}
        link={
          desktopL ? null : (
            <>
              <Link to="/my-games" className="my-games-header-link">
                Мои игры
              </Link>
            </>
          )
        }
        hideBurger={desktopL}
      />

      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={desktopL ? 'permanent' : 'temporary'}
      />

      {showEmailConfirm && <ConfirmEmail />}

      <MainWrap className={classes.content}>{children}</MainWrap>

      <Footer />

      <Dialog open={open} onClose={handleClose}>
        <Typography style={{ margin: '24px 24px 0' }} variant="h2">
          Вышла новая версия сервиса, улучшенная и переработанная
        </Typography>

        <DialogContent>
          <Features>
            {features.map((feature) => (
              <li>{feature}</li>
            ))}
          </Features>

          <Alert severity="warning" style={{ marginBottom: '24px' }}>
            Текущая версия развиваться не будет. Все обновления будут выходить только для новой
            версии
          </Alert>

          <Alert severity="warning" style={{ marginBottom: '12px' }}>
            Текущая версия будет отключена в январе 2025 года
          </Alert>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} style={{ marginRight: '24px' }} view="ghost">
            Остаться
          </Button>

          <Button href="https://app.embedgames.app" style={{ marginRight: '24px' }}>
            Перейти на новую версию
          </Button>
        </DialogActions>
      </Dialog>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  a {
    text-decoration: none;
  }
`;

const MainWrap = styled.main`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  flex-grow: 1;

  @media screen and (min-width: 1320px) {
    margin-left: 240px;
  }
`;

const SwitchVersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: 100%;
  background-color: #1565c0;
  color: #fff;
  z-index: 1200;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1254px;
  margin: 0 auto;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const TextDescription = styled.span`
  margin-right: 24px;
`;

const Features = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding-left: 0;

  li:first-letter {
    color: #2bae66;
  }
`;

export default BaseLayout;
