import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Sidebar, Topbar } from '../BaseLayout/components';
import { Footer } from '../Footer';

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingTop: 0,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
    },
  },
  content: {
    height: '100%',
  },
}));

const LandingLayout = (props: any) => {
  const { children, auth } = props;

  const classes = useStyles();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <Wrap
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        className="top-bar"
        auth={auth}
        link={
          <Link to="/my-games" className="my-games-header-link">
            Мои игры
          </Link>
        }
      />

      <Sidebar onClose={handleSidebarClose} open={openSidebar} />

      <MainWrap className={classes.content}>{children}</MainWrap>

      <Footer />
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  a {
    text-decoration: none;
  }
`;

const MainWrap = styled.main`
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  background-color: #F7F7F7;
  color: #333;
`;

export default LandingLayout;
