import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const VkIcon = ({ fill }: { fill: string }) => (
  <svg viewBox="0 0 32 32" width="40" height="40">
    <g id="surface1">
      <path
        fill={fill}
        d="M 9.09375 5 C 6.851563 5 5 6.851563 5 9.09375 L 5 22.90625 C 5 25.148438 6.851563 27 9.09375 27 L 22.90625 27 C 25.148438 27 27 25.148438 27 22.90625 L 27 9.09375 C 27 6.851563 25.148438 5 22.90625 5 Z M 9.09375 7 L 22.90625 7 C 24.066406 7 25 7.933594 25 9.09375 L 25 22.90625 C 25 24.066406 24.066406 25 22.90625 25 L 9.09375 25 C 7.933594 25 7 24.066406 7 22.90625 L 7 9.09375 C 7 7.933594 7.933594 7 9.09375 7 Z M 12.09375 10.5625 L 12.09375 21.4375 L 16.59375 21.4375 C 18.648438 21.4375 20.84375 20.542969 20.84375 18.09375 C 20.84375 16.109375 19.296875 15.609375 18.625 15.46875 L 18.625 15.4375 C 19.089844 15.242188 20.125 14.578125 20.125 13.09375 C 20.125 11.496094 19.046875 10.5625 16.34375 10.5625 Z M 14.875 12.5625 C 14.875 12.5625 15.910156 12.5625 16.15625 12.5625 C 17.285156 12.5625 17.34375 13.480469 17.34375 13.65625 C 17.34375 14.363281 16.835938 14.84375 16.1875 14.84375 C 16 14.84375 14.875 14.875 14.875 14.875 Z M 14.875 16.71875 C 14.875 16.71875 16.242188 16.710938 16.53125 16.71875 C 16.820313 16.726563 18.03125 16.785156 18.03125 18.0625 C 18.03125 19.335938 16.769531 19.425781 16.5 19.4375 C 16.230469 19.449219 14.875 19.4375 14.875 19.4375 Z "
      />
    </g>
  </svg>
);

// const TgIcon = ({ fill }: { fill: string }) => (
//   <svg height="32" viewBox="0 -39 512.00011 512" width="32">
//     <path
//       fill={fill}
//       d="m504.09375 11.859375c-6.253906-7.648437-15.621094-11.859375-26.378906-11.859375-5.847656 0-12.042969 1.230469-18.410156 3.664062l-433.398438 165.441407c-23 8.777343-26.097656 21.949219-25.8984375 29.019531s4.0390625 20.046875 27.4999995 27.511719c.140626.042969.28125.085937.421876.125l89.898437 25.726562 48.617187 139.023438c6.628907 18.953125 21.507813 30.726562 38.835938 30.726562 10.925781 0 21.671875-4.578125 31.078125-13.234375l55.605469-51.199218 80.652344 64.941406c.007812.007812.019531.011718.027343.019531l.765625.617187c.070313.054688.144532.113282.214844.167969 8.964844 6.953125 18.75 10.625 28.308594 10.628907h.003906c18.675781 0 33.546875-13.824219 37.878906-35.214844l71.011719-350.640625c2.851563-14.074219.460937-26.667969-6.734375-35.464844zm-356.191406 234.742187 173.441406-88.605468-107.996094 114.753906c-1.769531 1.878906-3.023437 4.179688-3.640625 6.683594l-20.824219 84.351562zm68.132812 139.332032c-.71875.660156-1.441406 1.25-2.164062 1.792968l19.320312-78.25 35.144532 28.300782zm265.390625-344.566406-71.011719 350.644531c-.683593 3.355469-2.867187 11.164062-8.480468 11.164062-2.773438 0-6.257813-1.511719-9.824219-4.257812l-91.390625-73.585938c-.011719-.011719-.027344-.023437-.042969-.03125l-54.378906-43.789062 156.175781-165.949219c5-5.3125 5.453125-13.449219 1.074219-19.285156-4.382813-5.835938-12.324219-7.671875-18.820313-4.351563l-256.867187 131.226563-91.121094-26.070313 433.265625-165.390625c3.660156-1.398437 6.214844-1.691406 7.710938-1.691406.917968 0 2.550781.109375 3.15625.855469.796875.972656 1.8125 4.289062.554687 10.511719zm0 0"
//     />
//   </svg>
// );

export const Footer = () => {
  return (
    <Wrap>
      <Inner>
        <ColumnsWrap>
          <Column padding>
            <Link to="/faq" className="footer-link">
              Вопросы и ответы
            </Link>

            <Link to="/pricing" className="footer-link">
              Тарифы
            </Link>
          </Column>

          <Column padding>
            <Link to="/policy" className="footer-link">
              Политика конфиденциальности
            </Link>

            <Link to="/term-of-use" className="footer-link">
              Пользовательское соглашение
            </Link>
          </Column>

          <Column>
            <ColumnTitle>Контакты</ColumnTitle>

            {/* <div>По вопросам обращайтесь в наше сообщество ВК</div> */}

            <Logos>
              <a href="https://vk.com/embedgames" target="_blank" rel="noopener noreferrer">
                <VkIcon fill="#fff" />
              </a>

              {/* <a href="https://t.me/dmitrsavk" target="_blank" rel="noopener noreferrer">
                <TgIcon fill="#fff" />
              </a> */}
            </Logos>
          </Column>
        </ColumnsWrap>
      </Inner>
    </Wrap>
  );
};

const Wrap = styled.footer`
  position: relative;
  z-index: 101;
  background-color: #5f31e0;
  color: #fff;
  border-top: 1px solid #fff;
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 40px 16px;

  @media screen and (min-width: 960px) {
    padding: 60px 24px;
  }
`;

const ColumnsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
`;

const Column = styled.div<{ padding?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  flex-shrink: 0;

  .footer-link {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
  }

  :last-child {
    margin-bottom: 0;
  }

  ${(p) =>
    p.padding &&
    `
    padding-top: 35px;
  `};

  @media screen and (min-width: 960px) {
    margin-bottom: 0;
    flex-shrink: 0;
    width: 33%;
  }
`;

const ColumnTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: space-between;

  a {
    transition: transform 0.2s ease;
  }

  a:hover {
    transform: scale(1.05);
  }
`;
